<template>
  <div>
<!--    <el-row :gutter="20">-->
<!--      <el-col :span="23">-->
        <!-- 查询 -->
        <el-form
            :inline="true"
            :model="this.pageInfo.params"
            class="demo-form-inline"
            width="100%"
            style="margin-left: 6px;width: 100%; margin-top: -6px;"
        >
          <el-form-item style="width: 100%;">
          <el-radio-group v-for='item of typeList' @change='serchs(item.id)' v-model='labelPosition' size='small'>
            <el-radio-button style='margin-right: 10px;' :label='item.id'>
              {{ item.value }}
<!--              <i class="el-icon-circle-plus"  @click="userAddData"></i>-->
            </el-radio-button>
          </el-radio-group>
<!--            <el-button type="primary" icon="el-icon-plus" @click="userAddData"-->
<!--            >新增</el-button-->
<!--            >-->
          </el-form-item>
        </el-form>
<!--      </el-col>-->
<!--    </el-row>-->
    <el-table
      :data='tableData'
      border
      style='width: 100%'>
      <el-table-column
        type='index'
        label='序号'
        header-align='center'
        align='center'
        width='60'
      >
      </el-table-column>
      <el-table-column
        prop='typeId'
        label='类型'
        :formatter='typeId'
        header-align='center'
        align='center'
      >
      </el-table-column>

      <el-table-column
        prop='code'
        label='编号'
        header-align='center'
        align='center'
      >
      </el-table-column>
      <el-table-column
        prop='name'
        label='名称'
        header-align='center'
        align='center'
      >
      </el-table-column>
      <el-table-column
        prop='sortOrder'
        header-align='center'
        align='center'
        label='排序'>
        <template slot-scope='scope'>
          <el-button icon='el-icon-bottom' size='small' @click="handleSort(scope.row.id, 'down')"></el-button>
          <el-button icon='el-icon-top' size='small' @click="handleSort(scope.row.id, 'up')"></el-button>
        </template>
      </el-table-column>
      <el-table-column
        header-align='center'
        align='center'
        label='状态'>
        <template slot-scope='scope'>
          <el-switch
            v-model='scope.row.isUsed'
            active-color='#13ce66'
            active-value='Y'
            inactive-value='N'
            class='main-switch'
            @change='derail(scope.row.id,scope.row.isUsed, scope.row.typeId)'
            inactive-color='#ff4949'>
          </el-switch>
        </template>
      </el-table-column>
    </el-table>
    <el-button style="margin-top: 20px;margin-bottom: 20px;" type="primary" icon="el-icon-plus" @click="userAddData">新增</el-button>
<!--    <div style="margin-top: 10px; height: 32px; width: 100%"></div>-->
    <el-dialog
        title="新增"
        :visible.sync="dialogFormVisible"
        :append-to-body="true"
        @close="close('formName')"
    >
      <el-form :model="form" :rules="rules" ref="formName" label-width="100px">
        <el-form-item label="名称：" class="form" prop="name">
          <el-input
              v-model="form.name"
              placeholder="请输入名称"
              class="formInput"
          ></el-input>
        </el-form-item>
        <el-form-item label="编号：" class="form" prop="code">
          <!-- <u-input v-model="form.loginName" type="text" :border="true" placeholder="请输入电话"> -->
          <el-input
              v-model="form.code"
              placeholder="请输入编码"
              class="formInput"
          ></el-input>
        </el-form-item>
<!--        <el-form-item label="图片地址：" class="form1" prop="picPath">-->
<!--          <el-upload-->
<!--              action=""-->
<!--              ref="upload"-->
<!--              list-type="picture-card"-->
<!--              :on-preview="handlePictureCardPreview"-->
<!--              :on-remove="handleRemove"-->
<!--              clearFiles-->
<!--              style="margin-left: 15px"-->
<!--              :file-list="imgList"-->
<!--              :limit="1"-->
<!--              :before-upload='imgUpload'-->
<!--          >-->
<!--            <i class="el-icon-plus"></i>-->
<!--          </el-upload>-->
<!--          <el-dialog :append-to-body="true" :visible.sync="dialogVisible">-->
<!--            <img width="100%" :src="dialogImageUrl" alt="" />-->
<!--          </el-dialog>-->
<!--        </el-form-item>-->
        <el-form-item
            style="
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: 10px 0;
          "
        >
          <el-button type="primary" @click="formSave('formName')">
            确 定</el-button
          >
          <el-button @click="formCancel('formName')">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryDictionaryTypeList,
  queryAllListByTypeId,
  queryListByTypeId,
  dictionarySort,
  dictionarySave,
  uploadFile,
  webServerHttpUrlString,
  isExistForCodeByTypeId
} from '@/api/dictionary'

export default {
  data() {
    return {
      tableData: [],
      pageInfo: {},
      labelPosition: 1,
      isUsed:0,
      typeList: [],
      dialogFormVisible:false,
      dialogVisible:false,
      dialogImageUrl:'',
      web:null, //图片路径
      form:{
        typeId:1,
        name:null,
        code:null,
        // picPath:null
      },
      imgList:[],
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入编码', trigger: 'blur' }],
        picPath: [
          { required: true, message: '请输入图片', trigger: 'blur' },
        ],
      },
    }

  },
  created() {
    this.getList()
    this.getTypelist()
    webServerHttpUrlString().then(res => {
      this.web = res;
    })
  },
  mounted() {
  },
  methods: {
    //新增按钮
    userAddData(){
      this.imgList = [];
      this.dialogFormVisible = true;
      this.form.name = null;
      this.form.code = null;
      console.log('this.form.typeId',this.form.typeId)
    },
    formSave(formName, loginName) {
      // let flag

        this.$refs[formName].validate(valid => {
          if (valid){
            isExistForCodeByTypeId(this.form.code,this.form.typeId).then(res => {
              if(res){
                this.$alert('编码重复！', '信息提示', {
                  confirmButtonText: '确定',
                  type: 'error'
                })
              }else {this.$confirm('您确定保存吗？', '信息提示', {
                cancelButtonClass: "btn-custom-cancel",
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              })
                  .then(() => {
                    dictionarySave(this.form).then(res => {
                      console.log(res)
                      this.$alert('保存成功！', '信息提示', {
                        confirmButtonText: '确定',
                        type: 'success'
                      }).then(() => {
                          this.removeIamge = []
                        this.getList()
                        this.dialogFormVisible = false;
                        this.$refs[formName].resetFields()
                      })
                    })
                  })
                  .catch(() => {
                  })}
          })
          } else {
            console.log('error submit!!')
            return false
          }
        })

    },
    //清空表单验证
    close(formName) {
      this.$refs[formName].resetFields()
    },
    formCancel(formName) {
      this.dialogFormVisible = false
      this.$refs[formName].resetFields()
    },
    // imgUpload(file) {
    //   this.imgList = [];
    //   console.log(file)
    //   let formdata = new FormData();
    //   formdata.append("folder", "sysAdmin")
    //   formdata.append("file", file)
    //
    //   uploadFile(formdata).then(res => {
    //     console.log('res', res)
    //     // this.dialogImageUrl = this.web + res;
    //     this.imgList.push({
    //       "url": this.web + res
    //     })
    //     console.log(this.imgList)
    //     this.form.headPortraitPath = res;
    //   })
    // },
    // // 文件列表移除文件时的钩子
    // handleRemove() {
    //   this.form.headPortraitPathHttpUrl = null
    // },
    // // 点击文件列表中已上传的文件时的钩子
    // handlePictureCardPreview(file) {
    //   this.dialogImageUrl = file.url
    //   this.dialogFormVisible = true
    //   this.dialogVisible = true
    // },

    typeId(row) {
      if (this.typeList != null) {
        for (let i = 0; i < this.typeList.length; i++) {
              if (row.typeId == this.typeList[i].id) {
                return this.typeList[i].value;
              }
            }
      }
    },
    indexMethod(index) {
      index = index + 1 * this.pageInfo.pageSize
      return index
    },
    serchs(id) {
      this.form.typeId = id
      this.labelPosition = id
      this.getList()
    },
    getList() {
      queryAllListByTypeId(this.labelPosition).then(res => {
        this.tableData = res
      })
      // queryListByTypeId(this.labelPosition).then(res => {
      //   // console.log(res,'123344')
      //   this.tableData = res
      // })
    },
    // 排序
    handleSort(id, upOrDown) {
      console.log('id', id)
      console.log('upOrDown', upOrDown)
      dictionarySort(id, upOrDown).then(() => {
        this.getList()
      })
    },
    derail(id, isUsed, typeId) {
      console.log(isUsed)
      console.log(id, isUsed)
      let statusList = { id, isUsed, typeId }
      console.log(statusList)
      dictionarySave(statusList).then(res => {
        console.log('res', res)
        this.getList()
      })
    },
    getTypelist() {
      queryDictionaryTypeList().then(res => {
        this.typeList = res
      })
    }
  }
}

</script>

<style lang='less' scoped>
.form{
  width: 89%;;
}
/deep/.el-dialog{
  width: 28%;
}
/deep/.el-col-23{
  width: 99.83333%;
}
/deep/ .el-form-item {
  margin-bottom: 10px;
}
</style>
