import {get,post} from "../http/index";

export const sysDictionaryPage = (params) => post('/sysDictionary/page',params);
export const queryDictionaryTypeList = () => get('/dictionary/queryDictionaryTypeList');
export const queryAllListByTypeId = (params) => get('/dictionary/queryAllListByTypeId/' + params)
export const queryListByTypeId = (params) => get('/dictionary/queryListByTypeId/' + params)
// export const sysDictionaryDelete = (params) => post('/sysDictionary/delete?id=' + params);
// export const sysDictionaryDetail = (params) => get( '/dictionary/queryAllListByTypeId/' + params);
// export const dictionaryQueryAllListByTypeId = (params) => get('/dictionary/queryAllListByTypeId/'+ params);
// export const sysDictionaryTypeMap = () => get('/sysMapConfig/sysDictionaryTypeMap');
export const dictionarySort = (id, upOrDown) =>get('/dictionary/sort/' + upOrDown + "/" + id);
//新增
export const dictionarySave = (params) => post('/dictionary/save',params)
// 图片上传集合
export const uploadFile = (params, folder) => post('/fileProcessing/uploadFile', params, folder)
// 获取图片链接
export const webServerHttpUrlString = () => get('/fileProcessing/webServerHttpUrl')
//检查code是否存在
export const isExistForCodeByTypeId = (code,id) => get('/dictionary/isExistForCodeByTypeId/' + code + "/" + id)
